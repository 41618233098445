<template>
  <div
    id="app"
    class="h-screen w-screen bg-base-100 max-w-full"
    :data-theme="currentTheme"
  >
    <div class="drawer lg:drawer-open">
      <input
        id="my-drawer-2"
        type="checkbox"
        class="drawer-toggle"
      >
      <div class="drawer-content flex flex-col">
        <!-- Page content here -->
        <label
          for="my-drawer-2"
          class="btn btn-primary drawer-button absolute lg:hidden"
        >
          <fa-icon icon="bars" />
        </label>
        <div class="flex flex-col justify-center bg-base-100">
          <section class="px-8 py-6 m-4 rounded-lg shadow-xl">
            <slot />
          </section>
        </div>
      </div>

      <nav class="drawer-side">
        <label
          for="my-drawer-2"
          class="drawer-overlay"
        />
        <div class="p-4 w-80 bg-base-100 text-base-content h-full">
          <NuxtLink href="/">
            <img
              class="mx-auto"
              alt=""
              :src="logo"
            >
          </NuxtLink>
          <hr class="mt-4 mb-2">
          <h1 class="font-bold mb-2 px-2">
            {{ name }}
          </h1>
          <hr class="mt-2 mb-2">
          <ul class="menu">
            <template v-if="user.reseller">
              <li class="menu-title">
                <span>{{ $t('nav.categoryReseller') }}</span>
              </li>
              <li>
                <NuxtLink to="/organizations/">
                  <fa-icon
                    icon="building"
                    fixed-width
                  />
                  {{ $t('nav.organizations') }}
                </NuxtLink>
              </li>
            </template>
            <li class="menu-title">
              <span>{{ $t('nav.categoryCustomerAdmin') }}</span>
            </li>
            <li>
              <NuxtLink :to="`/organizations/${user.org}`">
                <fa-icon
                  icon="building"
                  fixed-width
                />
                {{ $t('nav.myOrganization') }}
              </NuxtLink>
            </li>
            <li class="menu-title">
              <span>{{ $t('nav.categoryUser') }} {{ user.name }}</span>
            </li>
            <li>
              <a @click="toggleTheme">
                <fa-icon
                  icon="palette"
                  fixed-width
                />
                {{ $t('nav.theme') }}
              </a>
            </li>
            <li>
              <NuxtLink to="/language">
                <fa-icon
                  icon="language"
                  fixed-width
                />
                {{ $t('nav.language') }}
              </NuxtLink>
            </li>
            <li v-if="user.name">
              <a href="/auth/user">
                <fa-icon
                  icon="user"
                  fixed-width
                />
                {{ $t('nav.user') }}
              </a>
            </li>
            <li>
              <a href="/auth/api/logout">
                <fa-icon
                  icon="right-from-bracket"
                  fixed-width
                />
                {{ $t('nav.logout') }}
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useCurrentUser} from "~/composables/states";

const user = useCurrentUser()
const name = ref("Q-MEX Gateway")
const logo = ref("")

const currentColorScheme = ref(localStorage.getItem('theme') ?? "")
if (currentColorScheme.value === '') {
  // Load from prefers-color-scheme
  if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    currentColorScheme.value = 'dark'
  } else {
    currentColorScheme.value = 'light'
  }
}

const currentTheme = ref(currentColorScheme.value === 'dark' ? 'business' : 'cupcake')

function toggleTheme() {
  if (currentColorScheme.value === 'light') {
    currentColorScheme.value = 'dark'
    currentTheme.value = 'business'
    localStorage.setItem('theme', 'dark')
  } else {
    currentColorScheme.value = 'light'
    currentTheme.value = 'cupcake'
    localStorage.setItem('theme', 'light')
  }
}

</script>
